import React, { Component } from 'react';
import { Grid, Col, Row } from 'react-bootstrap';
import Cards from '../components/ServicesCards/';
import Carousel from '../components/Carousel';
import Welcome from '../components/Welcome/Welcome';
import ScrollTopOnMount from '../components/Scrolling/ScrollTopOnMount';
import servicesBackground from '../img/servicesBackground.jpeg';
import thinkwildlife from '../img/thinkwildlife.png';
import pinemartenproject from '../img/pinemartenproject.jpg';
import ipca from '../img/ipca.jpg';

const cardsStyle = {
  backgroundImage: `url(${servicesBackground})`,
  width: '100%',
  paddingBottom: '5vh'
};
class Home extends Component {
  render() {
    return (
      <div id="container">
        <ScrollTopOnMount />
        <Carousel />
        <Welcome />
        <div style={cardsStyle} className="services-container">
          <Cards />
        </div>
        <Grid style={{ paddingTop: '5vh' }}>
          <Row md={12}>
            <Col md={4} className="text-center">
              <a href="https://www.thinkwildlife.org/">
                <img
                  src={thinkwildlife}
                  alt="thumbnail"
                  className="img-thumbnail"
                />
              </a>
            </Col>
            <Col md={4} className="text-center">
              <a href="http://www.ipca.ie/">
                <img src={ipca} alt="thumbnail" className="img-thumbnail" />
              </a>
            </Col>
            <Col md={4} className="text-center">
              <a href="https://pinemarten.ie/">
                <img
                  src={pinemartenproject}
                  alt="thumbnail"
                  className="img-thumbnail"
                />
              </a>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Home;

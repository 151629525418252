import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Col, Row, Jumbotron, Button } from 'react-bootstrap';

class Welcome extends Component {
  render() {
    return (
      <Grid className="section-services">
        <Row>
          <Col xs={6} md={12}>
            <Jumbotron>
              <p className="welcome-heading text-center">
                Pest Control with Care
              </p>

              <hr className="hr-line" />
              <p className="w-text-a color-text-a">
                We here at Magee Environmental, are proud to avoid the use of
                Rodenticides and Pesticides where possible. We believe that
                caring for our wildlife and our environment means just that. We
                provide quality pest control services, whether you are a home
                owner, a retailer or a commercial business without the use of
                Rodenticide. Our aim to provide quality services through
                tracking and trapping methods.
                <br />
                We strongly advocate against Rodenticide usage for the treatment
                of rodents, helping to preserve a safer environment for our most
                vulnerable native species.
              </p>
              {/* <p>
                <Button className="btn btn-a">
                  <Link to="/services">Our Services</Link>
                </Button>
              </p> */}
            </Jumbotron>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Welcome;

import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ScrollTopOnMount from '../components/Scrolling/ScrollTopOnMount';
import residential from '../img/home.jpg';
import commercial from '../img/retail.jpg';
import pinemarten from '../img/pinemartenproject.jpg';
import chickenShed from '../img/chickenShed.jpg';

const servicesPadding = {
  paddingBottom: '2.5vh'
};
class Services extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <h1 className="w-title-a text-page-heading text-center">
          Our Services
        </h1>
        <hr className="hr-line" />
        <Container>
          <Row style={servicesPadding} id="residential">
            <Col style={servicesPadding} xs="6">
              <h3 className="w-title-a text-brand">
                <Link to="/services/residential">Residential Services</Link>
              </h3>
              <p className="w-text-a color-text-a">
                Nulla id lorem ut quam suscipit iaculis at vel purus. Nunc lacus
                magna, posuere ac ligula vel, tincidunt blandit nibh. Maecenas
                dignissim ipsum tristique, sagittis ex vel, consectetur enim.
                Integer semper laoreet tincidunt. Nullam non nisi a velit
                malesuada congue. Aliquam imperdiet aliquet odio ut congue.
                Aenean sodales at enim ultrices elementum. Integer pellentesque,
                justo a suscipit rhoncus, velit leo luctus mauris, ut
                pellentesque velit lacus sit amet ligula. Mauris fermentum nibh
                non nisl placerat consectetur. Proin facilisis mollis lacus eu
                efficitur. Phasellus eu varius arcu. Curabitur id orci ut quam
                consequat iaculis sed ac dolor. Suspendisse molestie sagittis
                porta.
                <br />
                <Link
                  style={{ textDecoration: 'underline' }}
                  to="/services/residential"
                >
                  More Info
                </Link>
              </p>
            </Col>
            <Col xs="6">
              <Row>
                <Col xs={12}>
                  <img
                    src={residential}
                    className="img-thumbnail"
                    alt="Residential Services"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={servicesPadding} id="commercial">
            <Col xs="6">
              <Row>
                <Col xs={12}>
                  <img
                    src={commercial}
                    className="img-thumbnail"
                    alt="Commercial Services"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <h3 className="w-title-a text-brand">
                <Link to="/services/commercial">Commercial Services</Link>
              </h3>
              <p className="w-text-a color-text-a">
                Nulla id lorem ut quam suscipit iaculis at vel purus. Nunc lacus
                magna, posuere ac ligula vel, tincidunt blandit nibh. Maecenas
                dignissim ipsum tristique, sagittis ex vel, consectetur enim.
                Integer semper laoreet tincidunt. Nullam non nisi a velit
                malesuada congue. Aliquam imperdiet aliquet odio ut congue.
                Aenean sodales at enim ultrices elementum. Integer pellentesque,
                justo a suscipit rhoncus, velit leo luctus mauris, ut
                pellentesque velit lacus sit amet ligula. Mauris fermentum nibh
                non nisl placerat consectetur. Proin facilisis mollis lacus eu
                efficitur. Phasellus eu varius arcu. Curabitur id orci ut quam
                consequat iaculis sed ac dolor. Suspendisse molestie sagittis
                porta.
                <br />
                <Link
                  style={{ textDecoration: 'underline' }}
                  to="/services/commercial"
                >
                  More Info
                </Link>
              </p>
            </Col>
          </Row>
          <Row style={servicesPadding} id="wildlife">
            <Col xs="6">
              <h3 className="w-title-a text-brand">
                <Link to="/services/wildlife">Wildlife Conservation</Link>
              </h3>
              <p className="w-text-a color-text-a">
                Nulla id lorem ut quam suscipit iaculis at vel purus. Nunc lacus
                magna, posuere ac ligula vel, tincidunt blandit nibh. Maecenas
                dignissim ipsum tristique, sagittis ex vel, consectetur enim.
                Integer semper laoreet tincidunt. Nullam non nisi a velit
                malesuada congue. Aliquam imperdiet aliquet odio ut congue.
                Aenean sodales at enim ultrices elementum. Integer pellentesque,
                justo a suscipit rhoncus, velit leo luctus mauris, ut
                pellentesque velit lacus sit amet ligula. Mauris fermentum nibh
                non nisl placerat consectetur. Proin facilisis mollis lacus eu
                efficitur. Phasellus eu varius arcu. Curabitur id orci ut quam
                consequat iaculis sed ac dolor. Suspendisse molestie sagittis
                porta.
                <br />
                <Link
                  style={{ textDecoration: 'underline' }}
                  to="/services/wildlife"
                >
                  More Info
                </Link>
              </p>
            </Col>
            <Col xs="6">
              <Row>
                <Col xs={12}>
                  <img
                    src={pinemarten}
                    className="img-thumbnail"
                    alt="Pine Marten Recovery Project"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={servicesPadding} id="agricultural">
            <Col xs="6">
              <Row>
                <Col xs={12}>
                  <img
                    src={chickenShed}
                    className="img-thumbnail"
                    alt="Commercial Services"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <h3 className="w-title-a text-brand">
                <Link to="/services/agricultural">Agriculture Services</Link>
              </h3>
              <p className="w-text-a color-text-a">
                Nulla id lorem ut quam suscipit iaculis at vel purus. Nunc lacus
                magna, posuere ac ligula vel, tincidunt blandit nibh. Maecenas
                dignissim ipsum tristique, sagittis ex vel, consectetur enim.
                Integer semper laoreet tincidunt. Nullam non nisi a velit
                malesuada congue. Aliquam imperdiet aliquet odio ut congue.
                Aenean sodales at enim ultrices elementum. Integer pellentesque,
                justo a suscipit rhoncus, velit leo luctus mauris, ut
                pellentesque velit lacus sit amet ligula. Mauris fermentum nibh
                non nisl placerat consectetur. Proin facilisis mollis lacus eu
                efficitur. Phasellus eu varius arcu. Curabitur id orci ut quam
                consequat iaculis sed ac dolor. Suspendisse molestie sagittis
                porta. <br />
                <Link
                  style={{ textDecoration: 'underline' }}
                  to="/services/agricultural"
                >
                  More Info
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Services;

import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

export class MapContainer extends Component {
  render() {
    const location = { lat: 54.0971473, lng: -7.6242078 };
    return (
      <Map
        google={this.props.google}
        style={{
          width: '92%',
          height: '285px',
          marginTop: '5px'
        }}
        className={'map'}
        initialCenter={{
          lat: 54.0971473,
          lng: -7.6242078
        }}
        zoom={9}
      >
        <Marker
          title={'Magee Environmental'}
          name={'MageeEnvironmental'}
          position={location}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBc3H2f27N1cFz4rdWkxDNhjTe_AQzDNKk'
})(MapContainer);

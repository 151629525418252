import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ScrollTopOnMount from '../../components/Scrolling/ScrollTopOnMount';
import pinemarten from '../../img/pinemartenproject.jpg';
import npws from '../../img/npws.png';

const servicesPadding = {
  paddingBottom: '2.5vh'
};
class Wildlife extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <div className="w-title-a text-page-heading text-center">
          Wildlife Services
        </div>
        <hr className="hr-line" />
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/services">Services</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Wildlife</BreadcrumbItem>
          </Breadcrumb>

          <Row style={servicesPadding} id="wildlife">
            <Col xs="6">
              <h3 className="w-title-a text-brand">Wildlife Conservation</h3>
              <p className="w-text-a color-text-a">
                Pest Control has come full circle and the old ways of the past
                must be relearned to help protect our native wildlife. We at
                Magee Environmental are specialists in tracking and trapping
                pests with the aim of safe relocation. Tracking enables us to
                determine the direction of entry, numbers present and prime
                trapping locations. When left alone, nature can manage
                themselves.
              </p>
              <p className="w-text-a color-text-a">
                By providing pests with food, it allows them to breed beyond
                control and what nature would have intended. Providing them with
                shelter, protects them from their natural predators. For many,
                the most common solution is to introduce poison to control their
                numbers. However, this endangers their natural predators. We
                here at Magee Environmental, do not use poisin, instead
                surveying safe areas where these pests can be relocated, without
                causing harm to their new habitat.
              </p>
              <p className="w-text-a color-text-a">
                We at Magee Environmental work under instruction of &nbsp;
                <a
                  style={{ color: '#2eca6a' }}
                  href="https://www.mammals-in-ireland.ie/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Vincent Wildlife Trust
                </a>
                &nbsp;to protect our native species. We provide monitored
                cameras on site for customers who want to see for themselves the
                many wonderful creatures that inhabit their area.
              </p>
            </Col>
            <Col xs="6">
              <Row>
                <Col xs={6}>
                  <img
                    src={pinemarten}
                    className="img-thumbnail"
                    alt="Pine Marten Recovery Project"
                  />
                </Col>
                <Col xs={6}>
                  <img
                    src={npws}
                    className="img-thumbnail"
                    alt="National Parks & Wildlife Service"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Wildlife;

import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import DrawerToggleButton from '../SideDrawer/ToggleButton';
import logo from '../../img/logo.png';
const style = {
  paddingRight: '3px',
  paddingTop: '3px',
  display: 'inline-block',
};
const Header = (props) => (
  <Navbar
    collapseOnSelect
    expand="lg"
    className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top"
  >
    <div className="toggle-button">
      <DrawerToggleButton click={props.drawerClickHandler} />
    </div>
    <Link to="/" className="navbar-brand text-brand">
      <span style={style}>
        <img src={logo} alt="Logo" />
      </span>
      Magee<span className="color-b">Environmental</span>
    </Link>
    <div
      className="navbar-collapse collapse justify-content-center"
      id="navbarDefault"
    >
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink
            exact
            to="/"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Home
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            to="/about"
            activeClassName="nav-link active"
            className="nav-link"
          >
            About
          </NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink
            exact
            to="/services"
            activeClassName="nav-link active"
            className="nav-link dropdown-toggle"
          >
            Services
          </NavLink>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/services/agricultural">
              Agricultural Services
            </Link>

            <Link className="dropdown-item" to="/services/commercial">
              Commercial Services
            </Link>
            <Link className="dropdown-item" to="/services/residential">
              Residential Services
            </Link>
            <Link className="dropdown-item" to="/services/wildlife">
              Wildlife Services
            </Link>
          </div>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            to="/covid19"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Covid-19
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            to="/testimonials"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Testimonials
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            to="/contact"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </div>
  </Navbar>
);

export default Header;

import React from 'react';
import 'react-bootstrap-carousel/dist/react-bootstrap-carousel.css';
import RBCarousel from 'react-bootstrap-carousel';
import { Link } from 'react-router-dom';
import Slide1 from '../../img/Slide1.jpeg';
import Slide2 from '../../img/Slide2.jpeg';
import Slide3 from '../../img/Slide3.jpeg';
import Slide4 from '../../img/agriculture.jpeg';

class HomeCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true
    };
  }
  onSelect = (active, direction) => {
    console.log(`active=${active} && direction=${direction}`);
  };

  slideNext = () => {
    this.slider.slideNext();
  };
  slidePrev = () => {
    this.slider.slidePrev();
  };
  goToSlide = () => {
    this.slider.goToSlide(4);
  };
  autoplay = () => {
    this.setState({ autoplay: !this.state.autoplay });
  };

  render() {
    let { leftIcon, rightIcon } = this.state;
    const slide1 = {
      backgroundImage: `url(${Slide1})`,
      width: '100%',
      height: '100vh',

    };
    const slide2 = {
      backgroundImage: `url(${Slide2})`,
      width: '100%',
      height: '100vh'
    };
    const slide3 = {
      backgroundImage: `url(${Slide3})`,
      width: '100%',
      height: '100vh'
    };

    const slide4 = {
      backgroundImage: `url(${Slide4})`,
      width: '100%',
      height: '100vh'
    };
    return (
      <div style={{ paddingBottom: '65px'}}>
        <div className="intro intro-carousel" id="carousel">
          <div className="owl-carousel owl-theme">
            <RBCarousel
              animation={true}
              autoplay={this.state.autoplay}
              slideshowSpeed={5500}
              leftIcon={leftIcon}
              rightIcon={rightIcon}
              ref={r => (this.slider = r)}
              version={4}
            >
              <div
                className="carousel-item-a intro-item bg-image"
                style={slide1}
              >
                <div className="overlay overlay-a" />
                <div className="intro-content display-table">
                  <div className="table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="intro-body">
                            <p className="intro-title-top">What we do</p>
                            <h1 className="intro-title mb-5">
                              <span className="color-b">Residential </span>
                              Property Pest Management
                            </h1>
                            <p className="intro-subtitle intro-price">
                              <Link to="/services/residential">
                                <span className="price-a">Residential</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item-a intro-item bg-image"
                style={slide2}
              >
                <div className="overlay overlay-a" />
                <div className="intro-content display-table">
                  <div className="table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="intro-body">
                            <p className="intro-title-top">What we do</p>
                            <h1 className="intro-title mb-5">
                              <span className="color-b">Commercial </span>
                              Property Pest Management
                            </h1>
                            <p className="intro-subtitle intro-price">
                              <Link to="/services/commercial">
                                <span className="price-a">Commercial</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item-a intro-item bg-image"
                style={slide3}
              >
                <div className="overlay overlay-a" />
                <div className="intro-content display-table">
                  <div className="table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="intro-body">
                            <p className="intro-title-top">What we do</p>
                            <h1 className="intro-title mb-5">
                              <span className="color-b">Conservation </span> of
                              Protected Species
                            </h1>
                            <p className="intro-subtitle intro-price">
                              <Link to="/services/wildlife">
                                <span className="price-a">Wildlife</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="carousel-item-a intro-item bg-image"
                style={slide4}
              >
                <div className="overlay overlay-a" />
                <div className="intro-content display-table">
                  <div className="table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="intro-body">
                            <p className="intro-title-top">What we do</p>
                            <h1 className="intro-title mb-5">
                              <span className="color-b">Agricultural </span>
                              Pest Management
                            </h1>
                            <p className="intro-subtitle intro-price">
                              <Link to="/services/agricultural">
                                <span className="price-a">Agriculture</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </RBCarousel>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeCarousel;

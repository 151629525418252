import React, { Component } from 'react';
import Map from '../components/Map/index';
import { Container, Row, Col } from 'reactstrap';
import ScrollTopOnMount from '../components/Scrolling/ScrollTopOnMount';

class Contact extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <h1 className="w-title-a text-page-heading text-center">
          Contact Information
        </h1>
        <hr className="hr-line" />
        <Container>
          <Row style={{ paddingBottom: '240px' }}>
            <Col lg="6" xs="12">
              <p>
                Magee Environmental, <br /> Ballyconnell,
                <br /> Cavan,
                <br />
                Ireland IE
              </p>
              <p className="contact-details">
                <span>
                  <i className="fas fa-phone" />
                  &nbsp;&nbsp;
                </span>
                <a href="tel:00353868472009">(086) 847 2009</a>
              </p>
              <p className="contact-details">
                <span>
                  <i className="fas fa-envelope" />
                  &nbsp;&nbsp;
                </span>
                <a href="mailto:mageeenvironmentalpestcontrol@gmail.com?Subject=Query%20From%20Website">
                  Email Us
                </a>
              </p>
            </Col>
            <Col lg="6" xs="12">
              <div>
                <Map />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Contact;

import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ScrollTopOnMount from '../../components/Scrolling/ScrollTopOnMount';
import residential from '../../img/home.jpg';

const servicesPadding = {
  paddingBottom: '2.5vh'
};
class Residential extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <div className="w-title-a text-page-heading text-center">
          Residential Services
        </div>
        <hr className="hr-line" />
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/services">Services</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Residential</BreadcrumbItem>
          </Breadcrumb>
          <Row style={servicesPadding} id="residential">
            <Col style={servicesPadding} xs="6">
              <h3 className="w-title-a text-brand">Residential Services</h3>
              <p className="w-text-a color-text-a">
               We can get your home pest free without the use of rodenticides. We use clean and green pest management techniques. We firstly survey and clear your home of possible harbourage sites. We then ensure your buildings have no weaknesses or exposed points of entry. We finally remove the risks of you providing free meals by providing you with guidance on waste management practices in your home.
              <br /> Our green pest management techniques ensure that your home is pest free and will continue to stay pest free, whilst protecting the environment and our native species.
              </p>
            </Col>
            <Col xs="6">
              <Row>
                <Col xs={6}>
                  <img
                    src={residential}
                    className="img-thumbnail"
                    alt="Residential Services"
                  />
                </Col>
                <Col xs={6}>
                  <img
                    src={residential}
                    className="img-thumbnail"
                    alt="Residential Services"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Residential;

import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollTopOnMount from '../components/Scrolling/ScrollTopOnMount';
import mouse from '../img/mouse.jpeg';

class About extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <h1 className="w-title-a text-page-heading text-center">About Us</h1>
        <hr className="hr-line" />
        <Container>
          <Row>
            <Col xs={9}>
              <p className="w-text-a color-text-a">
                Magee Environmental offers the highest quality of products and
                services on the market today. Since founded, we have provided
                superior service to our customers and have assisted them in
                achieving their goals. Our level of experience and commitment to
                excellence have earned us the reputation as the best pest
                control company in the area.
              </p>
              <p className="w-text-a color-text-a">
                At Magee Environmental, you receive the kind of quality and
                service you expect from a leader. Our company is always evolving
                as the needs of our customers change and as new opportunities
                are created in the market. You can rest assured that, working
                with Magee Environmental, you will enjoy the latest services,
                technology and developments in the industry.
              </p>
            </Col>
            <Col xs={3}>
              <img src={mouse} className="img-thumbnail" alt="Mouse " />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="w-text-a color-text-a">
                We make it our responsibility to know you and your business. We
                work closely with you to ensure that the solutions we provide
                are tailored to meet your unique needs and challenges. We are
                committed to your success. At Magee Environmental, our highest
                priority is satisfied customers. You are important to us and you
                can expect us to go that extra mile for your business. Superior
                customer service is the hallmark of Magee Environmental. We are
                proud to serve you and work hard to earn your business.
              </p>
            </Col>
          </Row>
        </Container>
        {/* <div className="w-title-a text-page-heading text-center">
          Meet our Team
        </div>
        <hr className="hr-line" /> */}
      </div>
    );
  }
}

export default About;

import React, { Component } from 'react';
import { Container } from 'reactstrap';
import ScrollTopOnMount from '../components/Scrolling/ScrollTopOnMount';

class Testimonials extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <h1 className="w-title-a text-page-heading text-center">
          Covid-19 Services
        </h1>
        <hr className="hr-line" />
        <Container>
          {/* <ScrollingHorizontally /> */}
          <p>
            We must all do our bit to stop the spread of Covid-19 to gaurantee a
            safer world for our loved ones. Throughout the rapidly changing
            events many companies are changing the way they operate, Magee
            Environmental are no different. We are following all governmental
            guidlines and practicing Social Distancing at all times with our
            Customers to ensure their and our safety.
          </p>
          <h3 className="w-title-a text-page-heading text-center">
            Treatment of Covid-19 Exposed Sites
          </h3>
          <p>
            We are specialists and certified in{' '}
            <em>Ultra Low Volume and Fogging Technologies</em> which are used to
            deal with bacteria and viruses. This Fogging Technology ensures a
            full knockdown of Coronavirus in any infected sites. The Anti-Viral
            product we use during the treatment is independently tested February
            2020 to European Standard BS EN 14476 against Coronaviruses and is
            <b> certified to kill Coronavirus.</b>
          </p>
          <p>
            We are available to treat and disinfect all sites, buildings,
            schools etc. which a person who has been tested positive for
            Covid-19 has been present. We can also treat buildings as a
            precautionary measure should it be deemed necessary. We use our
            Ultra Low Volume Fogging Technology in{' '}
            <strong>
              <em>Unoccupied buildings </em>
            </strong>
            such as offices, schools, homes etc. where is it possible to remove
            all people or pets from the premises.
            <br />
            Where it is not possible to remove people or pets from the
            premises,we will use our Compression Spray treatment which is safe
            to use in{' '}
            <strong>
              <em>Occupied buildings</em>
            </strong>
          </p>
          <p>About our treatment and products we use:</p>
          <p>
            <ul>
              <li>Fogging Technology so no mess to worry about</li>
              <li>
                <strong>
                  Independently tested February 2020 to European Standard BS EN
                  14476 against Coronaviruses
                </strong>
              </li>
              <li>Kills 99.99% of germs and viruses</li>
              <li>Effective within 5 minutes</li>
              <li>Cleans and disinfects</li>
              <li>Non bleach formula</li>
              <li>Safe for food preparation areas</li>
              <li>Non tainting</li>
              <li>General purpose sanitiser</li>
              <li>
                Tested to BS EN 14476, BS EN 1276 &amp; BS EN 13697 European
                Standards
              </li>
            </ul>
          </p>
        </Container>
      </div>
    );
  }
}

export default Testimonials;

import React from 'react';

const toggleButton = props => (
  <button className="toggleButton" onClick={props.click}>
    <div className="toggleButton-line" />
    <div className="toggleButton-line-middle" />
    <div className="toggleButton-line" />
  </button>
);
export default toggleButton;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Col, Row, Jumbotron } from 'react-bootstrap';

class pageNotFound extends Component {
  render() {
    return (
      <div id="content">
        <div className="page-not-found">
          <Grid className="section-services">
            <Row>
              <Col xs={6} md={12}>
                <Jumbotron>
                  <p className="not-found-heading text-center">
                    4<span className="color-b">0</span>4
                  </p>
                  <hr className="hr-line" />
                  <h1 className="w-text-a color-text-a text-center" style={{paddingBottom:'10px'}}>
                    We're sorry, the page you were looking for does not exist.
                  </h1>
                  <p className="not-found-return">
                    <Link to="/">
                      <span className="not-found-button">
                        Return to Homepage
                      </span>
                    </Link>
                  </p>
                </Jumbotron>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default pageNotFound;

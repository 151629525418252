import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import Footer from './components/Footer/Footer';
import Home from './views/Home';
import About from './views/About';
import Services from './views/Services';
import Residential from './views/services/Residential';
import Commercial from './views/services/Commercial';
import Wildlife from './views/services/Wildlife';
import Agricultural from './views/services/Agricultural';
import Testimonials from './views/Testimonials';
import Covid19 from './views/Covid19';
import Contact from './views/Contact';
import NotFound from './views/404';
import ScrollButton from './components/Scrolling/ScrollButton';
// import covid19Notify from './components/Covid19Notify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style.css';

class App extends Component {
  state = {
    sideDrawerOpen: false
  };
  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };
  render() {
    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <Router>
        <div style={{ height: '100%' }}>
          <Header drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          {backdrop}
          <div>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/services" component={Services} />
              <Route
                exact
                path="/services/residential"
                component={Residential}
              />
              <Route exact path="/services/commercial" component={Commercial} />
              <Route exact path="/services/wildlife" component={Wildlife} />
              <Route
                exact
                path="/services/agricultural"
                component={Agricultural}
              />
              <Route exact path="/covid19" component={Covid19} />
              <Route exact path="/testimonials" component={Testimonials} />
              <Route exact path="/contact" component={Contact} />
              <Route component={NotFound} />
            </Switch>
          </div>
          {/* <covid19Notify /> */}
          <Footer />
          <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
        </div>
      </Router>
    );
  }
}

export default App;

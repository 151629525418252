import React from 'react';
import {
  Container,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody
} from 'reactstrap';

import chickenShed from '../../img/chickenShed.jpg';
import residentialServices from '../../img/residentialServices.jpeg';
import retailServices from '../../img/retailServices.jpeg';
import wildlifeServices from '../../img/wildlifeServices.jpeg';

const showCardDeck = () => {
  return (
    <Container>
      <CardDeck className="services-container">
        {/* Residential Service Card */}
        <Card>
          <CardBody>
            <CardImg
              top
              width="100%"
              src={residentialServices}
              alt="Residential Services"
            />
            <CardTitle>Residential</CardTitle>
            <CardSubtitle>Services</CardSubtitle>
            <CardText>
              We offer a wide range of household services including pest
              management and fumigation against bugs and viruses.
            </CardText>
            <Button className="btn btn-a">Learn More</Button>
          </CardBody>
        </Card>
        {/* Retail Service Card */}
        <Card>
          <CardBody>
            <CardImg
              top
              width="100%"
              src={retailServices}
              alt="Commercial Services"
            />
            <CardTitle>Commercial</CardTitle>
            <CardSubtitle>Services</CardSubtitle>
            <CardText>
              We support both commercial and retail businesses to help supply
              their workforce and customers with a clean, safe place to work.
            </CardText>
            <Button className="btn btn-a">Learn More</Button>
          </CardBody>
        </Card>
        {/* Wildlife Service Card */}
        <Card>
          <CardBody>
            <CardImg
              top
              width="100%"
              src={wildlifeServices}
              alt="Wildlife Services"
            />
            <CardTitle>Wildlife</CardTitle>
            <CardSubtitle>Services</CardSubtitle>
            <CardText>
              We work with many charities and organisations to help them
              preserve and protect some of Ireland's most endangered wildlife.
            </CardText>
            <Button className="btn btn-a">Learn More</Button>
          </CardBody>
        </Card>
        {/* Agri Service Card */}
        <Card>
          <CardBody>
            {/* <CardImg
              top
              width="100%"
              src="https://placeholdit.imgix.net/~text?txtsize=33&txt=256%C3%97180&w=256&h=180"
              alt="Card image cap"
            /> */}
            <CardImg
              top
              width="100%"
              src={chickenShed}
              alt="Agricultural Services"
            />

            <CardTitle>Agricultural</CardTitle>
            <CardSubtitle>Services</CardSubtitle>
            <CardText>
              We help farmers care for their livestock with pest management
              including fumigation against common disease and mite infestations.
            </CardText>
            <Button className="btn btn-a">Learn More</Button>
          </CardBody>
        </Card>
      </CardDeck>
    </Container>
  );
};

export default showCardDeck;

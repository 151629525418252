import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../img/logo.png';
const style = {};
const SideDrawer = (props) => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  return (
    <nav className={drawerClasses}>
      <div>
        <Link to="/" className="navbar-brand text-brand">
          <span style={style}>
            <img src={logo} alt="Logo" />
          </span>
          Magee<span className="color-b">Environmental</span>
        </Link>
      </div>
      <ul>
        <li>
          <NavLink
            exact
            to="/"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/about"
            activeClassName="nav-link active"
            className="nav-link"
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/services"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Services
          </NavLink>
        </li>
        <li>
          <NavLink className="side-drawer-services" to="/services/agricultural">
            Agricultural Services
          </NavLink>
        </li>
        <li>
          <NavLink className="side-drawer-services" to="/services/commercial">
            Commercial Services
          </NavLink>
        </li>
        <li>
          <NavLink className="side-drawer-services" to="/services/residential">
            Residential Services
          </NavLink>
        </li>
        <li>
          <NavLink className="side-drawer-services" to="/services/wildlife">
            Wildlife Services
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/covid19"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Covid-19
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/testimonials"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Testimonials
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/contact"
            activeClassName="nav-link active"
            className="nav-link"
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default SideDrawer;

import React from 'react';
import { Col, Container, Row, Footer } from 'mdbreact';
import { Link } from 'react-router-dom';

class FooterPage extends React.Component {
  render() {
    return (
      <Footer className="font-small pt-4 mt-4 section-footer">
        <Container fluid className="text-center text-md-left">
          <Row>
            <Col md="3" className="info">
              <p>
                <i className="fas fa-map-marker-alt" />
                &nbsp;Ballyconnell, Co. Cavan.
              </p>
              <p>
                <i className="fas fa-phone" />
                &nbsp;
                <a href="tel:00353868472009">Call us at (086) 847 2009</a>
              </p>
              <p>
                <i className="fas fa-envelope" />
                &nbsp;
                <a href="mailto:mageeenvironmentalpestcontrol@gmail.com?Subject=Query%20From%20Website">
                  Email Us
                </a>
              </p>
            </Col>
            <Col md="6" className="info text-center">
              <b>
                <u>Opening Hours</u>
              </b>
              <p>
                Mon - Fri <br />
                08:00 - 20:00
              </p>
              <p>
                Saturday <br />
                09:00 - 17:00
              </p>
            </Col>
            <Col md="3">
              <Row>
                {/* <Col md="6">
                  <a href="https://www.npws.ie/">
                    <img src={npws} alt="thumbnail" className="img-thumbnail" />
                  </a>
                </Col> */}
                {/* <Col md="6">
                  <a href="https://www.thinkwildlife.org/">
                    <img
                      src={thinkwildlife}
                      alt="thumbnail"
                      className="img-thumbnail"
                    />
                  </a>
                </Col>
                <Col md="6">
                  <a href="https://www.mammals-in-ireland.ie/">
                    <img
                      src={vincenttrust}
                      alt="thumbnail"
                      className="img-thumbnail"
                    />
                  </a>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="socials-a">
          <ul className="list-inline">
            <li className="list-inline-item">
              <Link to="#">
                <i className="fab fa-facebook" aria-hidden="true" />
              </Link>
            </li>
            <li className="list-inline-item">
              <a href="https://twitter.com/cmagee451">
                <i className="fab fa-twitter" aria-hidden="true" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.linkedin.com/company/magee-environmental/">
                <i className="fab fa-linkedin" aria-hidden="true" />
              </a>
            </li>
          </ul>
        </div>
        <div className="copyright-footer text-center py-3">
          <Container className="info text-center" fluid>
            &copy; {new Date().getFullYear()} Copyright&nbsp;
            <a href="www.mageeenvironmental.com"> MageeEnvironmental </a>
          </Container>
        </div>
      </Footer>
    );
  }
}

export default FooterPage;

import React, { Component } from 'react';
import { Container } from 'reactstrap';
import ScrollTopOnMount from '../components/Scrolling/ScrollTopOnMount';

class Testimonials extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <h1 className="w-title-a text-page-heading text-center">
          Testimonials
        </h1>
        <hr className="hr-line" />
        <Container>
          {/* <ScrollingHorizontally /> */}
          <p>
            <strong>
              Please note, some of our clients wanted to give us a Testimonial
              but wished to remain anoymous due to the line of business they are
              in. Due to this, we have described the area of business they
              operate.
            </strong>
          </p>
          <div className="blockquote">
            <i className="fas fa-quote-left" />
            <p className="blockquote-before">
              We have been using Magee Environmental since we opened our store
              in 2009. Colm has always made himself available to us whenever we
              needed him. Although we have never had any serious pest issues, it
              is great piece of mind to know that the right person is looking
              after our pest control for us. We credit Magee Environmental for
              the fact we havent had any notable infestations.
            </p>
            <div className="pull-right">
              <i className="fas fa-quote-right pull-right blockquote-after" />
            </div>
            <small>
              <br /> <b>Food and Retail store, Cavan. </b>
            </small>
          </div>
          <div className="blockquote">
            <i className="fas fa-quote-left" />
            <p className="blockquote-before">
              We use all Magee Environmental at all our stores. We operate in
              the Animal Feed sector so infestations can be problematic from
              time to time. Colm has always been flexible and available whenever
              we needed him. Also transfers great knowledge of how to combat and
              reduce the risks of infestations.
            </p>
            <div className="pull-right">
              <i className="fas fa-quote-right pull-right blockquote-after" />
            </div>
            <small>
              <br />{' '}
              <b>Animal Feed stores, various locations in the North West. </b>
            </small>
          </div>
          {/* <div className="blockquote">
            <i className="fas fa-quote-left" />
            <p className="blockquote-before">
              At Magee Environmental, we've done our best to create a Web site
              that anticipates and satisfies our customers' needs. With that
              goal in mind, we've compiled a list of frequently asked questions.
              If you do not find an answer to your question here, feel free
              contact us at 086 3705040 or info@mageeenvironmental.com and we
              will give you our best advice.
            </p>
            <div className="pull-right">
              <i className="fas fa-quote-right pull-right blockquote-after" />
            </div>
            <small>
              <i>Paul Geery </i> <br /> <b>GEERY MOTOR FACTORS LTD. </b>
            </small>
          </div> */}
        </Container>
      </div>
    );
  }
}

export default Testimonials;

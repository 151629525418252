import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ScrollTopOnMount from '../../components/Scrolling/ScrollTopOnMount';
import commercial from '../../img/retail.jpg';

const servicesPadding = {
  paddingBottom: '2.5vh'
};
class Commercial extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <div className="w-title-a text-page-heading text-center">
          Commercial Services
        </div>
        <hr className="hr-line" />
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/services">Services</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Commercial</BreadcrumbItem>
          </Breadcrumb>
          <Row style={servicesPadding} id="commercial">
            <Col xs="6">
              <Row>
                <Col xs={6}>
                  <img
                    src={commercial}
                    className="img-thumbnail"
                    alt="Commercial Services"
                  />
                </Col>
                <Col xs={6}>
                  <img
                    src={commercial}
                    className="img-thumbnail"
                    alt="Commercial Services"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <h3 className="w-title-a text-brand">Commercial Services</h3>
              <p className="w-text-a color-text-a">
                We at Magee Environmental provide many specialised services for
                Commercial and Retail Companies. We provide comprehensve surveys
                of your premises using industry leading video technology, not
                available with other pest control companies. We take time to
                understand your company's situation, knowing that your business
                needs us to put in place the correct pest management measures as
                quickly as possible; and we will. <br />
                We also provide troubleshooting and auditing of large
                organisation's pest management systems to identify where their
                current systems could be failing or could be improved. <br />
                Magee Environmental also provides site maps/plans and reports
                for all company audits, including Bord Bia etc.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Commercial;

import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ScrollTopOnMount from '../../components/Scrolling/ScrollTopOnMount';
import chickenShed from '../../img/chickenShed.jpg';

const servicesPadding = {
  paddingBottom: '2.5vh'
};
class Agricultural extends Component {
  render() {
    return (
      <div id="content">
        <ScrollTopOnMount />
        <div className="w-title-a text-page-heading text-center">
          Agricultural Services
        </div>
        <hr className="hr-line" />
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/services">Services</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Agricultural</BreadcrumbItem>
          </Breadcrumb>
          <Row style={servicesPadding} id="wildlife">
            <Col xs="6">
              <h3 className="w-title-a text-brand">Agricultural Services</h3>
              <p className="w-text-a color-text-a">
                This page is under construction. More content coming soon.
              </p>
            </Col>
            <Col xs="6">
              <Row>
                <Col xs={6}>
                  <img
                    src={chickenShed}
                    className="img-thumbnail"
                    alt="Poultry Agri Services"
                  />
                </Col>
                <Col xs={6}>
                  <img
                    src={chickenShed}
                    className="img-thumbnail"
                    alt="Dairy Agri Services"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Agricultural;
